@import '~styles/base.module.scss';
/* MODAL */
.modalContainer {
  max-width: 472px !important;
  width: 472px !important;
  flex-basis: 35em;

  max-height: 100%;
  margin: 30px auto;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: c center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
  }
}
.modal {
  margin: 75px;
  margin-top: 10px;
  margin-bottom: 15px;
  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
  @include media('<small') {
    margin: 0% 5% 4% 2%;
  }
}
.content {
  height: 100% !important;
  padding-top: $space-m !important;
  padding-bottom: $space-l !important;
  width: 85%;
  margin: 0 auto;
}

.backdrop {
  backdrop-filter: blur(3px);
}

.title {
  padding-top: $space-l !important;
  padding-left: $space-xl !important;

  h2 {
    font-size: 22px !important;
    font-weight: 500 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.currentLogoText {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: rgba(66, 66, 66, 0.6);
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}

.logoWrapper {
  margin-top: $space-m !important;
  padding: $space-m !important;
  text-align: center !important;
  max-width: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.noLogoWrapper {
  margin-top: $space-xl !important;
  background-color: rgba(66, 66, 66, 0.2) !important;
  border-radius: 12px !important;
  padding: $space-l !important;
  width: 160px;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 12px !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: $color-black !important;
}

.selectFileWrapper {
  margin-top: $space-l;
  margin-bottom: $space-l;
  text-align: center;
}

.selectFileText {
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-bottom: $space-l !important;
}

.selectFileLabel {
  text-transform: uppercase !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: $color-primary !important;

  border-radius: 38px !important;
  border-color: transparent !important;
  padding: $space-s $space-m !important;

  cursor: pointer;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  input[type='file'] {
    display: none;
  }
}

.deleteFileButton {
  color: $color-error;
  text-transform: uppercase !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: $space-l !important;
  border-radius: 38px !important;
  border-color: transparent !important;
  padding: $space-s $space-m !important;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.uploadButton {
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  margin-bottom: $space-s;
}

.buttonCancelar {
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;
  &:hover {
    background-color: rgba($color-primary, 0.2);
  }
  &:disabled {
    color: $color-extra-light-gray;
  }
}

.error {
  margin-top: $space-l;
}
