@import '~styles/base.module.scss';

.content {
  margin: 0 auto;

  @include media('<small') {
    height: 100%;
    width: 100%;
  }
}

.infoIcon {
  height: 12px;
  width: 12px;
}

.textSize {
  padding-top: $space-m;
  color: grey;
  font-size: 12px !important;
  font-weight: 400;
}
