@import '~styles/base.module.scss';

.content {
  height: 100% !important;
  padding: $space-l !important;
  margin: 0 auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
  @include media('<small') {
    padding: 3px !important;
  }
}

.backdrop {
  backdrop-filter: blur(50px) !important;
  @include media('<small') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.title {
  padding: 0px !important;
  margin: 24px 184px 0px 70px !important;
  font-weight: bold;

  h2 {
    font-size: 21.33px !important;
    font-weight: 500 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}
