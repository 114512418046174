@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 500px !important;

  min-height: 70%;

  max-height: 90vh !important;

  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    max-height: 100vh !important;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.deviceCalibrationInfoLabel {
  font-size: 12px !important;
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  text-align: start !important;
  width: 100%;
  padding: 0.3%;

  input {
    padding: 18.5px 14px !important;
  }

  align-items: center;
  color: $color-primary !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus,
textarea:-ms-autofill,
textarea:-ms-autofill:hover,
textarea:-ms-autofill:focus,
select:-ms-autofill,
select:-ms-autofill:hover,
select:-ms-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-o-autofill,
input:-o-autofill:hover,
input:-o-autofill:focus,
textarea:-o-autofill,
textarea:-o-autofill:hover,
textarea:-o-autofill:focus,
select:-o-autofill,
select:-o-autofill:hover,
select:-o-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
textarea:-moz-autofill,
textarea:-moz-autofill:hover,
textarea:-moz-autofill:focus,
select:-moz-autofill,
select:-moz-autofill:hover,
select:-moz-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiOutlinedInput-notchedOutline {
  border-color: $color-border;
}

.input label span {
  display: none;
}

ul[role='menu'] {
  padding: 0 !important;
}

/*BUTTONS*/
.buttons {
  margin-top: 15px !important;

  @include media('<small') {
    margin-top: 0 !important;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  font-weight: 600px !important;
}

.buttonAdd {
  margin-top: 30px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .buttonAdd .arrow {
    margin-left: 0.35em !important;
    margin-right: 0 !important;
  }
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgb(198, 230, 248);
  }

  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.infoPublicWrapper {
  padding-top: 4% !important;
  display: flex;
  padding-bottom: 10%;
  font-size: 12px !important;
}

.iconPublic {
  padding-right: 5%;
}

.MuiTypography-root {
  font-size: 0.75rem !important;
  text-align: end !important;
  font-family: sans-serif !important;
}

MuiInputAdornment-positionEnd {
  font-size: 0.75rem !important;
  font-family: sans-serif !important;
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }
}

.hasError {
  border-color: $color-error !important;
}

.errorMessage {
  color: $color-error !important;
  margin: 10px 0 0 0 !important;
  background-color: rgba($color-error, 0.2) !important;
}

.cselect {
  margin-top: 1.05em !important;
  margin-bottom: 0 !important;

  :hover {
    cursor: text !important;
  }
}
