@import '~styles/base.module.scss';

/* MODAL */
.modalContainer {
  max-width: 472px !important;
  width: 472px !important;

  max-height: 90vh !important;

  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    max-height: 100vh !important;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;
  height: 80% !important;

  display: flex !important;
  flex-direction: column !important;

  @include media('<small') {
    height: 650px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.checkedLigthsWrapper {
  display: flex;
  font-family: bold !important;
  align-items: center;
  justify-content: space-between;
}

.header {
  margin: 7% 5% 9% 13%;
  font-weight: 500 !important;
  font-size: 22px !important;
}

.labelWrapperPublic {
  display: flex;
  flex-direction: row wrap;
}

.deviceLightsLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.deviceLightsInfoLabel {
  font-size: 12px !important;
}

.infoPublicWrapper {
  display: flex;
  padding-bottom: 10%;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.infoUpdateWrapper {
  display: flex;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.iconPublic {
  padding-right: 5%;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-inline: 10px;
  height: 7%;
  padding-bottom: 17px;
  padding-top: 10px;
}

.subheaderDeviceSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.subheaderEnviroment {
  padding: 4px;
  font-size: 12px;
}

.subheaderDevice {
  padding: 4px;
  font-size: 12px;
}

.subheaderEnviromentSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.input label span {
  display: none;
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  position: relative;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;
}

.input label span {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus,
textarea:-ms-autofill,
textarea:-ms-autofill:hover,
textarea:-ms-autofill:focus,
select:-ms-autofill,
select:-ms-autofill:hover,
select:-ms-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input:-o-autofill,
input:-o-autofill:hover,
input:-o-autofill:focus,
textarea:-o-autofill,
textarea:-o-autofill:hover,
textarea:-o-autofill:focus,
select:-o-autofill,
select:-o-autofill:hover,
select:-o-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
textarea:-moz-autofill,
textarea:-moz-autofill:hover,
textarea:-moz-autofill:focus,
select:-moz-autofill,
select:-moz-autofill:hover,
select:-moz-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.cselect {
  margin-top: 1.05em !important;
  margin-bottom: 0 !important;

  :hover {
    cursor: text !important;
  }
}

.custom {
  margin-bottom: 0 !important;
}

textarea {
  font-weight: 100;
  background-color: white;
  background-clip: padding-box;
  min-height: 47px;
  line-height: 40px;
}

.required {
  &:after {
    display: inline;
    color: $color-red;
    content: '*';
    margin-left: 2px;
  }
}

.hidden {
  display: none;
}

/*BUTTONS*/
.buttons {
  margin-top: 50% !important;

  @include media('<small') {
    margin-top: 0 !important;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0 auto;
}

.buttonSiguiente {
  margin-top: 25px;
  border-radius: 30px;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonAdd {
  margin-top: 30px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

/* ARROW NEXT */
.arrow {
  margin-left: 0.4em;
  margin-right: 0.4em;
}

/* HELPERS SUBMESAGES TEXTFIELDS */
.helper {
  color: #9d9d9d;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  margin-top: 0 !important;
}

.helperP {
  margin-bottom: 16px;
  color: #1b1a1a;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  display: inline;
}

/* PUBLIC WRAPPER */

.publicContainer {
  display: inline;
}

/* CHECKBOX */
.checkboxFormDiv {
  padding-top: 0;
  display: inline-block !important;
  align-items: flex-start;
  padding: 2px;
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: #fafafa;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }
}

.checkboxIcon {
  //el tick
  background-color: $color-white !important;
  color: $color-primary !important;
  border-radius: 2px;
}

.shareableLink {
  margin-top: $space-s;
}

/*ERROR MESSAGES*/
.hasError {
  border-color: $color-red !important;
}

.error {
  color: $color-red;
  margin: 0 !important;
}

.errorId {
  display: block;
  text-align: left;
}

.errorMessage {
  color: $color-error !important;
  margin: 10px 0 0 0 !important;
  background-color: rgba($color-error, 0.2) !important;
}
