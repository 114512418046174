@import '~styles/base.module.scss';

.qrContainer {
  margin-top: $space-s;
  height: 100px !important;
  width: 100px !important;

  @media screen and (max-height: '700px') {
    height: 80px !important;
    width: 80px !important;
  }
}

.qrText {
  line-height: 1.2em;
}
