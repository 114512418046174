@import '~styles/base.module.scss';

.updateText {
  font-size: 24px !important;

  @media all and (max-height: 680px) {
    font-size: 18px !important;
  }

  @include media('<tablet') {
    font-size: 14px !important;
  }

  @include media('<450px') {
    font-size: 12px !important;
  }
}
