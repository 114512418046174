@import '~styles/base.module.scss';

.fontDigit {
  font-family: 'Roboto';
  font-size: 42px;
  font-weight: 500;
  color: #424242;
  opacity: 0.9;
  margin-bottom: $space-s;

  @media only screen and (max-width: 1800px) and (min-width: 1650px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 1650px) and (min-width: 1400px) {
    font-size: 38px;
  }

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 1200px) and (min-width: 1050px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 1050px) and (min-width: 991px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 991px) and (min-width: 320px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 33px;
  }
}

.fontDate {
  opacity: 0.7;
  font-size: 25px;
  font-weight: 400;

  @media only screen and (max-width: 1800px) and (min-width: 1650px) {
    font-size: 23px;
  }

  @media only screen and (max-width: 1650px) and (min-width: 1400px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 1200px) and (min-width: 994px) {
    font-size: 17px;
  }

  @media only screen and (max-width: 994px) and (min-width: 320px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 20px;
  }
}
