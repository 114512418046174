@import '~styles/base.module.scss';

.modalContainer {
  max-width: 472px !important;
  width: 472px !important;

  max-height: 100% !important;

  flex-basis: 35em;
  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
  @include media('<small') {
    margin: 1% 5% 3% 5%;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-inline: 10px;
  height: 7%;
  padding-bottom: 3%;
  padding-top: 10px;
}

.subheaderDevicesSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px 10px;
  font-size: 12px;
}

.subheaderParams {
  padding: 4px;
  font-size: 12px;
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  text-align: start !important;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;
  label {
    margin-left: 10px !important;
  }
  p {
    margin-left: 10px;
    align-self: flex-start;
  }
}

.selectAllCheckboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;
  margin-top: $space-l;
  span {
    font-size: 18px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;
  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.devicesListPaper {
  height: 300px !important;
  max-height: 300px !important;
  overflow: auto;
  position: static !important;
}

.devicesList {
  max-height: 100%;
  overflow-x: hidden;
}

.noGroupWrapper {
  margin-bottom: $space-l;
}

.groupListItem {
  cursor: auto;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.expandIcon {
  cursor: pointer !important;
}

.listItemText {
  flex: 0 0 auto !important;
  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.typeListItem {
  cursor: auto;
  padding-bottom: $space-s !important;
  padding-top: $space-s !important;
  margin-left: $space-m !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.micaTypeItemText {
  flex: 0 0 auto !important;
  margin-left: $space-s;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.micaItem {
  flex: 0 0 auto !important;
  margin-left: $space-xl;
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;
  span {
    font-size: 14px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.wrapperError {
  height: 7px !important;
}

.error {
  color: $color-red !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.buttonWrapper {
  text-align: center;
  margin: 0;
}

.buttonSiguiente1 {
  margin-top: 34px;
  border-radius: 78px !important;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;
  &:hover {
    background-color: $color-primary-dark !important;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.buttonCancelar {
  margin-bottom: 50px;
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;
  &:hover {
    background-color: rgb(198, 230, 248);
  }
  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.subheaderDevices {
  padding: 4px;
  font-size: 12px;
}

.subheaderParamsSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px 10px;
  font-size: 12px;
}

.subInfo {
  color: $color-dark-gray;
  margin-bottom: $space-m !important;
  margin-top: 2em !important;
}

.wrapperInfoE {
  padding-top: 10px;
  display: flex;
  max-height: 45px !important;
  align-items: center;
  margin: 0 0 40% 0 !important;
}

.dataInput {
  justify-content: flex-end;
  display: flex;
  color: gray;
  align-items: stretch;
  white-space: nowrap;
}

.devicesInfoContainer {
  display: flex;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 25px;
  margin-bottom: 7px;
}

.devicesInfoIcon {
  margin-right: 10px;
}

.wrapperInfoButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;
  padding-left: 10px;
  text-transform: unset !important;
  background-color: $color-white;
  color: $color-black;

  &:hover {
    background-color: transparent;
    svg {
      color: #009be5;
    }
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  @include media('<small') {
    font-size: 0;
  }
}

.infoButton {
  padding: 0 !important;
  position: relative;
  &:hover {
    background-color: white !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.caja {
  background-color: $color-white;
  max-width: 70%;
  border-radius: 30px;
  z-index: 7000 !important;
  position: relative;
  left: 30px;
  top: 105px;
  bottom: 0px;
  padding: 5%;
  margin: -40px;
  box-shadow: 0 0 5px grey !important;
}

.wrapperDataInfo {
  display: flex !important;
  align-content: baseline !important;
  align-items: center;
  margin-left: auto;
  justify-content: space-between;
}

.inputField {
  max-width: 50px;
  display: flex;
}

.inputUnit {
  display: inline-block;
  font-weight: 450;
  width: 100%;
  text-align: right !important;
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.unitRangeWrapper {
  display: table;
  width: 100%;
  padding-top: 14% !important;
  margin-bottom: $space-m;

  @include media('<small') {
    margin-left: $space-s;
  }
}

.select {
  float: right;
  display: table-cell;

  font-size: 16px !important;
  font-weight: 500 !important;
  color: $color-primary !important;

  &:focus {
    background-color: $color-white !important;
  }
}

.selectIcon {
  color: $color-primary;
}

.selectFocus {
  &:focus {
    background-color: $color-white !important;
  }
}

.menuItemSelected {
  background-color: $color-primary-light !important;
}

.hasError {
  border-color: $color-border !important;
}

.cajaEmail {
  background-color: $color-white;
  max-width: 115%;
  border-radius: 30px;
  z-index: 7000 !important;
  position: relative;
  left: 40px;
  top: 140px;
  bottom: 0px;
  padding: 10%;
  margin: -40px;
  box-shadow: 0 0 5px grey !important;
}

.buttonAdd {
  margin-top: 5%;
  border-radius: 78px !important;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 5%;
  &:hover {
    background-color: $color-primary-dark !important;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.wrapperInfo {
  padding-top: 10px;
  display: flex;
  max-height: 45px !important;
  align-items: center;
  margin: 0 !important;

  span {
    font-size: 14px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkboxEmailWrapper {
  margin-top: 5% !important;
  margin-left: 10%;
  margin-bottom: -10% !important;
  overflow: hidden;
  overflow-y: scroll;
  height: 310px;
}

.checkboxFormDiv {
  width: 100% !important;
  padding-top: 0;
  display: flex !important;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    margin: 0 !important;
    margin: 0px !important;
    margin-left: 5px !important;
  }

  span {
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-right: 7px;
    padding: 3px 0px 3px 0px;
    border-radius: 4px;
  }
}

.confirmed {
  color: black;
}

.notConfirmed {
  color: lightgray;
}

.buttonSiguiente3 {
  margin-top: 24% !important;
  border-radius: 78px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  @include media('<small') {
    margin-top: 15% !important;
  }
}

.deleteEmailRecipientIcon {
  float: right;
  margin-left: auto;
  padding: 0 !important;
  color: rgba($color-error, 0.8) !important;

  &:focus {
    border-color: transparent !important;
    outline: none !important;
    border: none !important;
  }
}

.input label span {
  display: none;
}
