@import '~styles/base.module.scss';

.content {
  display: flex;
  justify-content: center; /* Alinea horizontalmente */
  align-items: center; /* Alinea verticalmente */
  height: 100vh; /* Ocupa toda la altura de la ventana */
  padding: $space-l !important;
  margin: 0 auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
  @include media('<small') {
    padding: 0 !important;
  }
}
