@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 500px !important;

  max-height: 90vh !important;
  min-height: 70%;
  margin: auto;
  padding: 1px 4px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  padding-bottom: $space-m !important;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 23px;
  margin-bottom: 7px;
}

.subheaderDevicesSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 6px 15px;
  font-size: 12px;
}

.devicesListPaper {
  max-height: 400px;
  overflow: auto;
}

.devicesList {
  max-height: 100%;
  overflow-x: hidden;
}

.noGroupWrapper {
  margin-bottom: $space-l;
}

.listItemText {
  flex: 0 0 auto !important;

  span {
    font-size: 14px;
    font-weight: 500 !important;
  }
}

.typeRowWrapper {
  margin-left: $space-m !important;
}

.expandIcon {
  cursor: pointer !important;
}

.groupListItem {
  cursor: auto;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.typeListItem {
  cursor: auto;
  padding-bottom: $space-s !important;
  padding-top: $space-s !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.micaTypeItemText {
  flex: 0 0 auto !important;
  margin-left: $space-m;

  span {
    font-size: 14px;
    font-weight: 500 !important;
  }
}

.micaItem {
  flex: 0 0 auto !important;
  margin-left: $space-xl;
}

.input {
  margin-top: $space-m !important;
  margin-bottom: 0.25em !important;
  text-align: start !important;
  width: 100%;
  align-items: center;
  color: $color-primary !important;

  input {
    padding: 13px 7px;
  }

  label {
    margin-left: 10px !important;
  }
}

.subInfo {
  display: table-cell;
  vertical-align: middle;
  color: $color-dark-gray;
  margin-bottom: $space-s;
  margin-right: $space-l;
  font-size: 18px;
}

.subInfoWrapper {
  margin-top: $space-s;
}

.selectAllCheckboxForm {
  display: flex;
  align-items: flex-start;
  margin-top: $space-m !important;
  margin: 0;

  span {
    font-size: 18px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.label {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 18px !important;
}

.error {
  color: $color-red;
  margin-top: $space-m;
  margin-bottom: $space-m;
}

.hasError {
  margin-bottom: 10px !important;

  div:first-child {
    &:before {
      border-bottom: none !important;
    }

    border-bottom: 2px solid $color-red !important;
  }
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 14px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
}

.buttonSiguiente {
  margin-top: 25px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonCancelar {
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:disabled {
    color: $color-extra-light-gray;
    background-color: $color-white;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.required {
  &:after {
    display: inline;
    color: $color-red;
    content: '*';
    margin-left: 2px;
  }
}

.hasError {
  &:before {
    border-bottom: none !important;
  }

  div:hover:before {
    border-bottom: none !important;
  }

  div:after {
    border-bottom: none !important;
  }
}

.submitingIcon {
  height: 24.5px;
  width: 24.5px;
  margin-left: 10px;
  overflow: visible;

  & span {
    width: 34.5px;
    height: 34.5px;
    overflow: visible;
    padding-bottom: 10px;
  }
}
