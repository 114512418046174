@import '~styles/base.module.scss';

th {
  margin-left: 2px;
  text-align: left;
}

.modalContainer {
  max-width: 484px !important;
  width: 484px !important;
  max-height: 100% !important;
  flex-basis: 35em;
  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;
  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px 10px !important;
    backdrop-filter: none !important;
  }

  @include media('<341px') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px 0px !important;
    backdrop-filter: none !important;
  }
}

.modal {
  margin: 60px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s $space-xs;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.3em;
  justify-content: first baseline;

  @include media('<small') {
    margin: 1% 5% 3% 5%;
  }
}

.fullScreenHeader {
  padding-top: 20px;
  width: 100%;
  margin-bottom: 50px;
}

.fullScreenTitle {
  width: 100%;
  padding-bottom: 1%;
}

.fullScreenSubtitle {
  width: 100%;
  padding-bottom: 1%;
}

.fullScreenContent {
  padding-top: 30px;
}

.devicesDropdown {
  width: 80%;
}

.deviceCard {
  width: 100%;
}

.deviceInfo {
  display: flex;
}

.deviceName {
  width: 70%;
}

.deviceType {
  width: 30%;
}

.customizingViewDisabled {
  opacity: 0.1;
}

.parametersContent {
  margin-top: 10%;
  padding: 2% 0% 0% 2%;
}

.viewTypeContent {
  margin-top: 10%;
  padding: 2% 0% 0% 2%;
}

/*NAV HEADERS*/
.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-inline: 10px;
  height: 7%;

  padding: 10px 0px 3% 0px;
}

.subheaderSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 10px !important;
  display: flex;
  align-items: center;

  @include media('<tablet') {
    font-size: 10px !important;
  }

  @include media('<small') {
    padding: 5px 7px;
  }
}

.subheader {
  padding: 5px 10px;
  font-size: 10px !important;
  display: flex;
  align-items: center;

  @include media('<tablet') {
    font-size: 10px !important;
  }

  @include media('<small') {
    padding: 5px 7px;
  }
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  text-align: start !important;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;

  label {
    margin-left: 10px !important;
  }
}

.inputField {
  max-width: 100px;
  display: flex;
}

.width {
  max-width: 70px !important;
  margin-right: 5px !important;
  margin-left: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus,
textarea:-ms-autofill,
textarea:-ms-autofill:hover,
textarea:-ms-autofill:focus,
select:-ms-autofill,
select:-ms-autofill:hover,
select:-ms-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input:-o-autofill,
input:-o-autofill:hover,
input:-o-autofill:focus,
textarea:-o-autofill,
textarea:-o-autofill:hover,
textarea:-o-autofill:focus,
select:-o-autofill,
select:-o-autofill:hover,
select:-o-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
textarea:-moz-autofill,
textarea:-moz-autofill:hover,
textarea:-moz-autofill:focus,
select:-moz-autofill,
select:-moz-autofill:hover,
select:-moz-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.MuiOutlinedInput-notchedOutline {
  border-color: $color-border;
}

.input label span {
  display: none;
}

.selectDevicesHelper {
  font-size: 13px;
  margin-bottom: $space-s;
  margin-top: $space-s;
}

.noGroupWrapper {
  margin-bottom: $space-l;
}

.expandIcon {
  cursor: pointer !important;
}

.groupListItem {
  cursor: auto;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.typeListItem {
  cursor: auto;
  padding-bottom: $space-s !important;
  padding-top: $space-s !important;
  margin-left: $space-m !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.devicesListPaper {
  height: 300px !important;
  max-height: 300px !important;
  overflow: auto;
  position: static !important;
}

.devicesList {
  max-height: 100%;
  overflow-x: hidden;
}

.listItemText {
  flex: 0 0 auto !important;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.groupOptionsButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;

  background-color: $color-white;
  padding: $space-s $space-m;
  margin-bottom: $space-m;
  margin-right: 8px;

  &:hover {
    background-color: $color-extra-extra-light-gray;
    opacity: 80%;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    font-size: 0;
  }
}

.micaTypeItemText {
  flex: 0 0 auto !important;
  margin-left: $space-s;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

ul[role='menu'] {
  padding: 0 !important;
}

.micaItem {
  flex: 0 0 auto !important;
  margin-left: $space-xl;
}

.navItem {
  margin-left: $space-xs;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  color: $color-primary;

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.errorWrapper {
  color: $color-error !important;
}

/*BUTTONS*/
.buttonWrapper {
  text-align: center;
  margin: 0;
  font-weight: 600px !important;
  position: static;
}

.buttonSiguiente1 {
  margin-top: 13%;
  border-radius: 78px !important;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.buttonSiguiente2 {
  border-radius: 78px !important;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.buttonSiguiente3 {
  margin-top: 24% !important;
  border-radius: 78px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }
}

.wrapperError {
  height: 7px !important;
  margin: 3% !important;
}

.buttonSiguiente {
  margin-top: 5px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  box-shadow: 3px 4px 3px rgb(168, 167, 167) !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: #006db3 !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none !important;
    border: none !important;
  }

  .arrow {
    margin-left: 0.35em !important;
    margin-right: 0 !important;
  }
}

.buttonCancelar {
  margin-bottom: 50px;
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgb(198, 230, 248);
  }

  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.visualizeQRWrapper {
  display: flex;
  font-family: bold !important;
  align-items: center;
  justify-content: space-between;
}

.infoPublicWrapper {
  display: flex;
  padding-top: 5%;
  padding-bottom: 60%;
}

.iconPublic {
  padding-right: 5%;
}

.menuItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto';
  padding-top: 7px;
  padding-bottom: 7px;

  transition: color 0.3s ease;
  cursor: pointer;
  color: $color-text;
  width: 100%;

  &:hover {
    background-color: rgba(0, 155, 229, 0.2) !important;
  }

  &:active {
    background-color: rgba(0, 155, 229, 0.5) !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.menuItemFocus {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto';
  padding-top: 7px;
  padding-bottom: 7px;

  transition: color 0.3s ease;
  cursor: pointer;
  color: $color-text;
  width: 100%;
  background-color: rgba(0, 155, 229, 0.35);

  &:hover {
    background-color: rgba(0, 155, 229, 0.35) !important;
  }
}

.wrapperDefaultValues {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxList {
  padding-bottom: 50% !important;
}

.checkboxWrapper {
  padding: 7%;
  display: flex;
  flex-flow: column wrap;
}

.paramWrapper {
  height: 367px !important;
  max-height: 367px !important;
  padding: 0% 4% 0 0;
  flex-flow: column wrap;
  margin-bottom: $space-xl;

  @include media('<small') {
    padding: 0% 4% 0 0;
  }
}

.paramWrapper1 {
  height: 367px !important;
  max-height: 367px !important;
  padding: 0% 5% 0 0;
  flex-flow: column wrap;
}

.checkboxFormDiv {
  padding-top: 0;
  display: inline-block !important;
  align-items: flex-start;
  padding: 2px;
  padding-right: 10px;
}

.checkboxFormDivA {
  padding-top: 10px;
  width: 60% !important;
  padding-left: 6% !important;
  border-spacing: 0 !important;
  display: block;
}

.dataInput {
  justify-content: flex-end;
}

.unitsParams {
  text-align: end !important;
  font-size: 0.75rem !important;
  font-family: sans-serif !important;

  .MuiTypography-root {
    font-size: 0.75rem !important;
    font-family: sans-serif !important;
  }

  .MuiInputAdornment-positionEnd {
    font-size: 0.75rem !important;
    font-family: sans-serif !important;
  }
}

.MuiTypography-root {
  font-size: 0.75rem !important;
  text-align: end !important;
  font-family: sans-serif !important;
}

MuiInputAdornment-positionEnd {
  font-size: 0.75rem !important;
  font-family: sans-serif !important;
}

.inputUnit {
  display: inline-block;
  font-weight: 450;
  width: 100%;
  text-align: right !important;

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.paramFormDiv {
  padding-top: 0;
  display: flex;
  align-items: center;
  max-height: 45px !important;
  padding: none;
}

.wrapperDataInfo {
  display: flex !important;
  align-content: baseline !important;
  align-items: center;
  margin-left: auto;
  justify-content: space-between;
}

.checkboxFormDisabled {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 16px !important;
    margin-right: 7px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
    color: #424242 !important;
    opacity: 0.4;
  }
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 16px !important;
    margin-right: 7px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.wrapperInfo {
  padding-top: 10px;
  display: flex;
  max-height: 45px !important;
  align-items: center;
  margin: 0 !important;

  span {
    font-size: 14px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.disabledBox {
  svg {
    color: rgba(0, 0, 0, 0.38) !important;
    margin: 0;
    padding: 0;
  }
}

.selectAllCheckboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 18px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.selectAllCheckbox {
  display: flex;
  align-items: flex-start;
  margin: 0;
  margin-top: $space-m;

  span {
    font-size: 18px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkboxAligned {
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }

  &:disabled {
    svg {
      color: black;
    }
  }
}

.inputParam {
  text-align: end !important;
  font-weight: 800px !important;

  &:hover {
    background-color: $color-primary !important;
  }
}

.checkboxIcon {
  //el tick
  background-color: $color-white !important;
  color: $color-primary !important;
  border-radius: 2px;
}

.hasError {
  border-color: $color-border !important;
}

.checkboxIcon {
  background-color: $color-primary;
  color: $color-white !important;
  border-radius: 4px;
}

.radioButtonRow {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 5% !important;
}

.radioButton {
  color: $color-primary !important;
  width: 15%;
}

.radioButtonLabel {
  width: 70%;
  border-radius: 65px !important;
  border: 2px solid $color-primary !important;
  padding: 2% 0% 2% 2% !important;
}

.openScreenButton {
  color: $color-white !important;
  background-color: $color-primary !important;
  border-radius: 38px;
  float: right;
  margin-top: 5%;
}

.error {
  color: $color-red !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.errorWarning {
  color: $color-red !important;
  margin-top: 40px !important;
  padding: 0 !important;
}

.wrapperInfoButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;
  padding-left: 10px;
  text-transform: unset !important;
  background-color: $color-white;
  color: $color-black;

  &:hover {
    background-color: $color-extra-extra-light-gray;
    opacity: 80%;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    font-size: 0;
  }
}

.infoButton {
  padding: 0 !important;

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.dialogInfo {
  border-radius: 50px;
}

.caja {
  background-color: $color-white;
  border-radius: 30px;
  z-index: 7000 !important;
  position: relative;
  left: 31px;
  bottom: 0px;
  padding: 5%;
  box-shadow: 0 0 5px grey !important;
}

.wrap {
  max-height: 65px !important;
  padding-top: 0;
}

.inputUnity {
  max-width: 2px;
  padding: 7px;
  margin-left: auto;
  font-size: '0.85rem';
  color: grey;
}

.datePicker {
  margin-bottom: 15px;

  button:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  div:hover:before {
    border-bottom: 2px solid rgba(0, 155, 229, 0.87) !important;
  }

  div:after {
    border-bottom: none !important;
  }

  div:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.datePicker.hasError {
  &:before {
    border-bottom: none !important;
  }

  div:hover:before {
    border-bottom: none !important;
  }

  div:after {
    border-bottom: none !important;
  }
}

.hasError {
  border-color: $color-red !important;
}

.error {
  color: $color-error !important;
  margin: 0 !important;
}

.errorMessage {
  color: $color-error !important;
  margin: $space-l 0 0 0 !important;
  background-color: rgba($color-error, 0.2) !important;
}

.errorId {
  display: block;
  text-align: left;
}

.datetimePickerWrapper {
  padding-top: 7%;
  display: flex;
  flex-flow: column wrap;
}

.occupationHourRow {
  width: 100%;
  margin-bottom: $space-m;
}

.occupationHourTitle {
  padding: $space-s;
}

.occupationHourCheckRow {
  width: 100%;
}

.datetimePickerWrapper1 {
  padding-left: 3%;
  display: flex;
  flex-flow: column wrap;
}

.schedule {
  margin-top: 15px !important;
  font-size: 18px !important;
}

.datetimePickerWrapper2 {
  display: table-cell;
  vertical-align: middle;
}

.datePickerWrapper {
  padding: 7%;
  display: flex;
  flex-flow: column wrap;
}

.radioGroup {
  display: flex;
  padding-left: $space-m;

  label {
    margin-right: 50px;
  }
}

.subInfo {
  display: table-cell;
  vertical-align: middle;
  color: #424242 !important;
  opacity: 0.4;
  padding-bottom: $space-m;
  margin-right: $space-l;
  font-size: 18px !important;
}

.subInfoSchedule {
  display: table-cell;
  vertical-align: middle;
  color: #424242 !important;
  opacity: 0.4;
  padding-top: $space-s;
  padding-bottom: $space-xs;
  margin-right: $space-l;
  font-size: 18px !important;
}

.subInfoDisabled {
  display: table-cell;
  vertical-align: middle;
  color: $color-dark-gray;
  padding-bottom: $space-m;
  margin-right: $space-l;
  font-size: 18px;
}

.subInfo1 {
  border: 1 solid gray !important;
  padding-left: 0% !important;
  border-collapse: collapse !important;
  color: $color-dark-gray;
  font-size: 15px;
}

.picker {
  display: flex;
  flex-flow: column;
  margin-bottom: $space-m;
}

.activateSecondOccupationHoursWrapper {
  display: flex;
  font-family: bold !important;
  align-items: center;
  justify-content: space-between;
}

.selectBorder {
  padding-right: 7px;
  border-radius: 40px !important;
  border: 1px solid rgba(66, 66, 66, 0.6) !important;
  margin-left: 50px;
}

.selectRangeGray {
  opacity: 0.4;
  color: #424242 !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  border-radius: 40px;
  border: 1px solid transparent !important;
  line-height: 30px;

  outline: none;
}

.selectRange {
  color: #009be5 !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  border-radius: 40px;
  border: 1px solid transparent !important;
  line-height: 30px;

  outline: none;
}

.alligned {
  display: flex;
  align-items: center;
  margin-bottom: $space-m !important;
}

.deviceNumber {
  color: #424242;
  font-size: 14px;
  padding-top: $space-m;
}

.firstOccupancyTime {
  display: flex !important;
  justify-content: space-around;
  width: 60%;
  align-items: center;
  margin-top: -8px !important;

  @include media('<small') {
    width: 70%;
  }
}

.timePickerContent {
  width: 90px !important;
  padding-right: 0px !important;

  input {
    padding: 6px !important;
    padding-right: 0px !important;
    text-align: center !important;
  }
}

.timePicker {
  width: 100% !important;

  button {
    display: none !important;

    &:focus {
      outline: none !important;
    }
  }
}

.secondOccupancyTime {
  display: flex !important;
  justify-content: space-around;
  width: 60%;
  align-items: center;
  margin-top: -8px !important;

  @include media('<small') {
    width: 70%;
  }
}

.hover_visible:hover {
  .information_box {
    display: block;
    top: auto !important;
    left: auto !important;
  }
}

.information_box {
  position: absolute;
  z-index: 9999999999999999;
  transition: 0.3s all ease;
  display: none;
  padding: 0 0 0 10px;

  .information {
    width: auto;
    padding: 25px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    font-family: roboto;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }

  p {
    font-size: 15px;
    text-align: left;
    max-width: 270px;
  }
}

.infoIcon {
  margin-right: $space-s !important;
  width: 15px !important;
  height: 15px !important;
}

.occupationInterval {
  font-size: 20px !important;
}
