@import '~styles/base.module.scss';

.content {
  display: flex;
  height: 100% !important;
  width: 100%;
  margin: 33px auto;

  @include media('<small') {
    margin: 0px;
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.backdrop {
  backdrop-filter: blur(50px) !important;

  @include media('<small') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}
