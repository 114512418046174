@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 320px !important;
  height: 260px;
  padding: 30px;
  margin: auto;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  padding-bottom: $space-m !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    max-height: 100vh !important;
  }
}

.modal {
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.buttonWrapper {
  text-align: center;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button:hover {
    cursor: pointer;
  }
}

.buttonSiguiente {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  border: none;
  margin-bottom: 0 !important;
  padding: 10px 25px;
  height: 35px;
  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonCancelar {
  margin-top: 5px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 30px;
  width: 127px;
  height: 35px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:disabled {
    color: $color-extra-light-gray;
    background-color: $color-white;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.modalContent {
  margin: 7px 0;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
