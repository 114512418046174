@import '~styles/base.module.scss';

.progress_chart {
  position: relative !important;
  width: 105px;
  height: 105px;
  margin-top: -10px;

  svg {
    width: 105px !important;
    height: 105px !important;
  }

  @include media('<tablet') {
    width: 70px;
    height: 70px;

    svg {
      width: 70px !important;
      height: 70px !important;
    }
  }
}

.progress_chart_little {
  position: relative !important;
  width: 70px;
  height: 70px;

  svg {
    width: 70px !important;
    height: 70px !important;
  }
}

.progress_chart__circle {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(-83deg);
  transform-origin: center;
  width: 100%;
  height: 100%;

  @include media('<tablet') {
    top: 0px;
    left: 0px;
  }
}

.progress_chart__circleLittle {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(-83deg);
  transform-origin: center;
  width: 100%;
  height: 100%;
}

.progress_chart__arc3 {
  fill: transparent;
  stroke-width: 5%;

  @include media('<tablet') {
    stroke-width: 5%;
  }
}

.spaces {
  padding: 2px;

  @include media('<tablet') {
    padding: 1px;
  }
}

.progress_chart__percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400 !important;
  font-size: 30px;

  @include media('<tablet') {
    font-size: 25px;
  }
}

.progress_chart__percentageLittle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400 !important;
  font-size: 26px;

  @include media('<tablet') {
    font-size: 25px;
  }
}
