@import '~styles/base.module.scss';

.modalContainer {
  max-width: 472px !important;
  width: 472px !important;

  max-height: 99vh !important;

  flex-basis: 35em;
  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;

  @include media('<small') {
    margin: 1% 5% 3% 5%;
  }
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-inline: 10px;
  height: 7%;
  padding-bottom: 3%;
  padding-top: 10px;
}

.subheaderDevicesSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.subheaderParams {
  padding: 4px;
  font-size: 12px;
}

.selectAllCheckboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;
  margin-top: $space-l;

  span {
    font-size: 18px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.devicesListPaper {
  height: 400px !important;
  max-height: 400px !important;
  overflow: auto;
  position: static !important;
}

.devicesList {
  max-height: 100%;
  overflow-x: hidden;
}

.noGroupWrapper {
  margin-bottom: $space-l;
}

.groupListItem {
  cursor: auto;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.expandIcon {
  cursor: pointer !important;
}

.listItemText {
  flex: 0 0 auto !important;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.typeListItem {
  cursor: auto;
  padding-bottom: $space-s !important;
  padding-top: $space-s !important;
  padding-left: 32px;

  &:hover {
    background-color: $color-white !important;
  }
}

.micaTypeItemText {
  flex: 0 0 auto !important;
  margin-left: $space-s;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.micaItem {
  flex: 0 0 auto !important;
  margin-left: $space-xl;
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 14px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  text-align: start !important;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;

  label {
    margin-left: 10px !important;
  }
}

.buttonSiguiente1 {
  margin-top: 14%;
  border-radius: 78px !important;
  background-color: $color-primary !important;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.buttonCancelar {
  margin-bottom: 50px;
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgb(198, 230, 248);
  }

  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.error {
  color: $color-red !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.useAccount {
  padding: $space-m 0 $space-m 0;
  text-align: center;
}

.emailText {
  font-size: 17px;
  font-weight: 390;
}

.emailUser {
  font-size: 12px;
  padding-top: 2px;
  padding-left: $space-xs;
}

.infoButton {
  width: 20px;
  height: 20px;
  padding: 0 !important;
  position: relative;

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.infoSize {
  width: 20px;
  height: 20px;
}

.infoIcon {
  display: flex;
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  font-weight: 600 !important;
  position: static;
}

.errorMessage {
  color: $color-error !important;
  margin: 0 0 $space-s 0 !important;
  background-color: rgba($color-error, 0.2) !important;
}
