.componentContainer {
  position: fixed;
  z-index: 999;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  @media screen and (max-height: 740px) {
    bottom: 10px;
    right: 20px;
    width: 90%;
  }
}
.supportButtonContainer {
  width: 100%;
  img {
    float: right;
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.supportBox {
  width: 355px;
  height: 440px;
  background-color: #f9fafa;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
}
.boxHeader {
  background-color: #369be5;
  color: #ffff;
  font-weight: 500;
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxContentContainer {
  padding: 5px 20px;
  width: 100%;
  height: 100%;
}
.boxContent {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  border-radius: 7px;
}
.contentHeader {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.headerTitle {
  width: 100%;
  font-size: medium;
  color: #256db3;
}
.headerSubtitle {
  width: 100%;
  font-size: 12px;
}
.contentBody {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}
.bodyTitle {
  width: 100%;
  color: #256db3;
}
.bodySubtitle {
  width: 100%;
  font-size: 12px;
}
.bodyList {
  margin-top: 5px;
}
.listText {
  font-size: 12px;
}
.bodyFooter {
  font-size: 12px;
}
