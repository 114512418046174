@import '~styles/base.module.scss';

.noIndicator {
  @include media('>1500px') {
    width: 200px !important;
    height: 200px !important;
  }

  @media all and (max-height: 760px) {
    width: 90px !important;
    height: 90px !important;
  }

  @media all and (max-height: 740px) {
    width: 80px !important;
    height: 80px !important;
  }

  @media all and (min-height: 760px) {
    @media all and (max-height: 800px) {
      width: 100px !important;
      height: 100px !important;
    }
  }

  @media all and (min-height: 800px) {
    @media all and (max-height: 860px) {
      width: 120px !important;
      height: 120px !important;
    }
  }

  @media all and (min-height: 860px) {
    @media all and (max-height: 960px) {
      width: 150px !important;
      height: 150px !important;
    }
  }
}

.circle4 {
  @include media('<1500px') {
    width: 200px !important;
    height: 200px !important;

    @media all and (max-height: 980px) {
      width: 160px !important;
      height: 160px !important;
    }

    @media all and (max-height: 870px) {
      width: 140px !important;
      height: 140px !important;
    }
  }

  @media all and (max-height: 790px) {
    width: 100px !important;
    height: 100px !important;
  }

  @media all and (max-height: 750px) {
    width: 90px !important;
    height: 90px !important;
  }

  @media all and (min-height: 790px) {
    @media all and (max-height: 830px) {
      width: 120px !important;
      height: 120px !important;
    }
  }
}

.noIndicator4 {
  @include media('<1500px') {
    width: 200px !important;
    height: 200px !important;
  }

  @include media('<1300px') {
    width: 180px !important;
    height: 180px !important;
  }

  @media all and (max-height: 670px) {
    width: 180px !important;
    height: 180px !important;
  }

  @media all and (max-height: 650px) {
    width: 150px !important;
    height: 150px !important;
  }

  @media all and (max-height: 620px) {
    width: 120px !important;
    height: 120px !important;
  }

  @media all and (max-height: 590px) {
    width: 100px !important;
    height: 100px !important;
  }
}

.parameterText {
  text-align: center;
  font-size: 28px !important;
  width: 100%;
  margin-top: $space-s;

  @media all and (max-height: 780px) {
    font-size: 25px !important;
    margin-top: $space-xs;
    line-height: 1em;
  }

  @media all and (max-height: 590px) {
    font-size: 18px !important;
  }

  @include media('<992px') {
    font-size: 18px !important;
    margin-top: $space-xs;
  }

  @include media('<tablet') {
    font-size: 13px !important;
    margin-top: $space-s;
  }
}

.parameterTextSmall {
  text-align: center;
  font-size: 22px !important;
  width: 100%;
  margin-top: $space-s;
  line-height: 1em;

  @media all and (max-height: 780px) {
    font-size: 20px !important;
    margin-top: $space-xs;
  }

  @include media('<1200px') {
    font-size: 17px !important;
    margin-top: $space-s;

    @media all and (max-height: 780px) {
      margin-top: $space-xs;
    }
  }

  @media all and (max-height: 680px) {
    font-size: 17px !important;
  }

  @media all and (max-height: 590px) {
    font-size: 17px !important;
  }

  @include media('<tablet') {
    font-size: 13px !important;
    margin-top: $space-s;
  }
}

.parameterTextMicaNoIndicator {
  text-align: center;
  font-size: 25px !important;
  width: 100%;
  margin-top: $space-s;
  line-height: 1em;

  @media all and (max-height: 780px) {
    font-size: 21px !important;
    margin-top: $space-xs;
  }

  @include media('<1050px') {
    font-size: 19px !important;
    margin-top: $space-xs;
  }

  @media all and (max-height: 680px) {
    font-size: 19px !important;
  }

  @media all and (max-height: 590px) {
    font-size: 17px !important;
  }

  @include media('<tablet') {
    font-size: 13px !important;
    margin-top: $space-s;
  }
}

.parameterTextMicaNoIndicatorMedium {
  text-align: center;
  font-size: 28px !important;
  width: 100%;
  margin-top: $space-s;
  line-height: 1em;

  @media all and (max-height: 780px) {
    font-size: 25px !important;
    margin-top: $space-xs;
  }

  @include media('<992px') {
    font-size: 20px !important;
    margin-top: $space-xs;
  }

  @media all and (max-height: 680px) {
    font-size: 20px !important;
  }

  @media all and (max-height: 590px) {
    font-size: 18px !important;
  }

  @include media('<tablet') {
    font-size: 13px !important;
    margin-top: $space-s;
  }
}

.circleContiner {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: '950px') {
    height: auto;
  }

  @include media('<tablet') {
    width: 40%;
  }
}

.circleContiner2 {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  @include media('<tablet') {
    width: 40%;
  }
}

.circleContiner4 {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: '950px') {
    height: auto;
  }

  @include media('<tablet') {
    width: 33%;
  }
}

.circleContiner10 {
  width: 16%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: '950px') {
    height: auto;
  }

  @include media('<tablet') {
    width: 33%;
  }
}

.circleContiner8 {
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: '950px') {
    height: auto;
  }

  @include media('<tablet') {
    width: 33%;
  }
}

.circleValue {
  width: 100%;
  opacity: 0.7;
  text-align: center;

  @media only screen and (min-width: 1500px) {
    font-size: 35px !important;

    @media all and (max-height: 750px) {
      font-size: 30px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 25px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    font-size: 33px !important;

    @media all and (max-height: 750px) {
      font-size: 30px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 25px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    font-size: 30px !important;

    @media all and (max-height: 750px) {
      font-size: 30px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 25px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 959px) {
    font-size: 29px !important;

    @media all and (max-height: 681px) {
      font-size: 25px !important;
    }
  }

  @media only screen and (max-width: 959px) and (min-width: 320px) {
    font-size: 22px !important;

    @media all and (max-height: 681px) {
      font-size: 22px !important;
    }
  }

  @media only screen and (max-width: 320px) {
    font-size: 22px !important;

    @media all and (max-height: 681px) {
      font-size: 22px !important;
    }
  }

  @media all and (max-height: 590px) {
    font-size: 22px !important;
  }
}

.circleValueSmall {
  width: 100%;
  opacity: 0.7;
  text-align: center;

  @media only screen and (min-width: 1500px) {
    font-size: 31px !important;

    @media all and (max-height: 750px) {
      font-size: 26px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 21px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    font-size: 29px !important;

    @media all and (max-height: 750px) {
      font-size: 26px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 21px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    font-size: 26px !important;

    @media all and (max-height: 750px) {
      font-size: 26px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 21px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 959px) {
    font-size: 25px !important;

    @media all and (max-height: 681px) {
      font-size: 21px !important;
    }
  }

  @media only screen and (max-width: 992px) {
    font-size: 15px !important;
  }

  @media all and (max-height: 590px) {
    font-size: 15px !important;
  }
}

.circleValueMedium {
  width: 100%;
  opacity: 0.7;
  text-align: center;

  @media only screen and (min-width: 1500px) {
    font-size: 33px !important;

    @media all and (max-height: 750px) {
      font-size: 28px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 23px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    font-size: 31px !important;

    @media all and (max-height: 750px) {
      font-size: 28px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 23px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    font-size: 28px !important;

    @media all and (max-height: 750px) {
      font-size: 28px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 23px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 959px) {
    font-size: 27px !important;

    @media all and (max-height: 681px) {
      font-size: 23px !important;
    }
  }

  @media only screen and (max-width: 992px) {
    font-size: 16px !important;
  }

  @media all and (max-height: 590px) {
    font-size: 16px !important;
  }
}

.unitValue {
  opacity: 0.7;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 1500px) {
    font-size: 25px !important;

    @media all and (max-height: 750px) {
      font-size: 20px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 18px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 16px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    font-size: 23px !important;

    @media all and (max-height: 750px) {
      font-size: 20px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 18px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 16px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    font-size: 21px !important;

    @media all and (max-height: 750px) {
      font-size: 20px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 18px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 16px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 959px) {
    font-size: 20px !important;

    @media all and (max-height: 750px) {
      font-size: 20px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 18px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 16px !important;
    }
  }

  @media only screen and (max-width: 959px) and (min-width: 320px) {
    font-size: 13px !important;
  }

  @media only screen and (max-width: 320px) {
    font-size: 12px !important;
  }
}

.unitValueMedium {
  opacity: 0.7;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 1500px) {
    font-size: 24px !important;

    @media all and (max-height: 750px) {
      font-size: 18px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 17px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 15px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    font-size: 22px !important;

    @media all and (max-height: 750px) {
      font-size: 19px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 17px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 15px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    font-size: 20px !important;

    @media all and (max-height: 750px) {
      font-size: 19px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 17px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 15px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 959px) {
    font-size: 19px !important;

    @media all and (max-height: 750px) {
      font-size: 19px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 17px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 15px !important;
    }
  }

  @media only screen and (max-width: 959px) {
    font-size: 11px !important;
  }
}

.unitValueSmall {
  opacity: 0.7;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 1500px) {
    font-size: 22px !important;

    @media all and (max-height: 750px) {
      font-size: 16px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 15px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    font-size: 20px !important;

    @media all and (max-height: 750px) {
      font-size: 17px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 15px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    font-size: 18px !important;

    @media all and (max-height: 750px) {
      font-size: 17px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 15px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 959px) {
    font-size: 17px !important;

    @media all and (max-height: 750px) {
      font-size: 17px !important;
    }

    @media all and (max-height: 681px) {
      font-size: 15px !important;
    }

    @media all and (max-height: 590px) {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-width: 959px) {
    font-size: 11px !important;
  }
}

.centred {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mediumCircle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1500px) {
    width: 160px !important;
    height: 160px !important;

    @media screen and (max-height: '900px') {
      width: 130px !important;
      height: 130px !important;
    }

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    width: 150px !important;
    height: 150px !important;

    @media screen and (max-height: '900px') {
      width: 130px !important;
      height: 130px !important;
    }

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    width: 140px !important;
    height: 140px !important;

    @media screen and (max-height: '900px') {
      width: 130px !important;
      height: 130px !important;
    }

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 991px) {
    width: 120px !important;
    height: 120px !important;

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 991px) {
    width: 70px !important;
    height: 70px !important;
  }

  @media screen and (max-height: '680px') {
    width: 70px !important;
    height: 70px !important;
  }
}

.smallCircle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1500px) {
    width: 140px !important;
    height: 140px !important;

    @media screen and (max-height: '900px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '850px') {
      width: 110px !important;
      height: 110px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    width: 130px !important;
    height: 130px !important;

    @media screen and (max-height: '900px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '850px') {
      width: 110px !important;
      height: 110px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    width: 120px !important;
    height: 120px !important;

    @media screen and (max-height: '900px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '850px') {
      width: 110px !important;
      height: 110px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 991px) {
    width: 110px !important;
    height: 110px !important;

    @media screen and (max-height: '850px') {
      width: 110px !important;
      height: 110px !important;
    }

    @media screen and (max-height: '750px') {
      width: 95px !important;
      height: 95px !important;
    }
  }

  @media only screen and (max-width: 991px) {
    width: 67px !important;
    height: 67px !important;
  }

  @media screen and (max-height: '680px') {
    width: 67px !important;
    height: 67px !important;
  }
}

.xsmallCircle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1500px) {
    width: 120px !important;
    height: 120px !important;

    @media screen and (max-height: '900px') {
      width: 100px !important;
      height: 100px !important;
    }

    @media screen and (max-height: '850px') {
      width: 90px !important;
      height: 90px !important;
    }

    @media screen and (max-height: '750px') {
      width: 75px !important;
      height: 75px !important;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    width: 110px !important;
    height: 110px !important;

    @media screen and (max-height: '900px') {
      width: 100px !important;
      height: 100px !important;
    }

    @media screen and (max-height: '850px') {
      width: 90px !important;
      height: 90px !important;
    }

    @media screen and (max-height: '750px') {
      width: 75px !important;
      height: 75px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    width: 100px !important;
    height: 100px !important;

    @media screen and (max-height: '900px') {
      width: 100px !important;
      height: 100px !important;
    }

    @media screen and (max-height: '850px') {
      width: 90px !important;
      height: 90px !important;
    }

    @media screen and (max-height: '750px') {
      width: 75px !important;
      height: 75px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 991px) {
    width: 90px !important;
    height: 90px !important;

    @media screen and (max-height: '850px') {
      width: 90px !important;
      height: 90px !important;
    }

    @media screen and (max-height: '750px') {
      width: 75px !important;
      height: 75px !important;
    }
  }

  @media only screen and (max-width: 991px) {
    width: 67px !important;
    height: 67px !important;
  }

  @media screen and (max-height: '680px') {
    width: 70px !important;
    height: 70px !important;
  }
}

.bigCircle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1500px) {
    width: 200px !important;
    height: 200px !important;
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    width: 180px !important;
    height: 180px !important;

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 110px !important;
      height: 110px !important;
    }
  }

  @media only screen and (max-width: 1300px) and (min-width: 1100px) {
    width: 155px !important;
    height: 155px !important;

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 110px !important;
      height: 110px !important;
    }
  }

  @media only screen and (max-width: 1100px) and (min-width: 959px) {
    width: 140px !important;
    height: 140px !important;

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 110px !important;
      height: 110px !important;
    }
  }

  @media only screen and (max-width: 959px) and (min-width: 500px) {
    width: 120px !important;
    height: 120px !important;

    @media screen and (max-height: '850px') {
      width: 120px !important;
      height: 120px !important;
    }

    @media screen and (max-height: '750px') {
      width: 110px !important;
      height: 110px !important;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 95px !important;
    height: 95px !important;
  }

  @media screen and (max-height: '680px') {
    width: 90px !important;
    height: 90px !important;
  }
}

.circleBorder {
  border-width: 4px;

  @include media('<tablet') {
    border-width: 2px;
  }
}

.preheatingIcon {
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
}

.preheatingIconContainer {
  margin: 4px 0px;
}
