@import '~styles/base.module.scss';

.modalContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 484px !important;
  width: 484px !important;
  min-height: 648px !important;
  max-height: 100% !important;
  flex-basis: 35em;
  margin: auto;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;
  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px 10px !important;
    backdrop-filter: none !important;
  }

  @include media('<341px') {
    position: center;
    width: 100% !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px 0px !important;
    backdrop-filter: none !important;
  }
}
