@import '~styles/base.module.scss';

.box {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;

  @include media('<tablet') {
    justify-content: space-around;
  }
}

.weatherLocation {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media('<tablet') {
    margin-left: 0px !important;

    h6 {
      margin: 0px !important;
      font-size: 10px;
      font-weight: 500;
    }
  }
}
.outdoorWeather {
  font-size: smaller;
  @include media('>tablet') {
    display: none;
  }
}

.locationIcon {
  font-size: 60px !important;
  margin-right: $space-s;

  @include media('<1100px') {
    margin-right: 0px;
  }

  @include media('<tablet') {
    width: 30px !important;
    height: 30px !important;
  }
}

.paramContainer {
  height: 60px;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  span {
    flex-basis: 100%;
    text-align: center;
  }

  @include media('<1100px') {
    margin-right: $space-m;
  }

  @include media('<tablet') {
    margin-right: 0px !important;
  }
}

.responsiveAdress {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  font-size: larger;
  @include media('<tablet') {
    display: none !important;
  }
}

.transformIcon {
  transform: translate(24px, 14px) scale(4);

  @include media('<tablet') {
    transform: translate(20px, 15px) scale(2.9);
  }
}
.parameterTitle {
  font-size: medium;
  @include media('<tablet') {
    display: none;
  }
}

.temperatureValue {
  @include media('<tablet') {
    font-size: 14px;
    align-self: center;
    margin: 5px;
  }
}

.weatherIconWrapper {
  height: 60px;
  @include media('<tablet') {
    padding-right: 10px !important;
    height: 35px;
    margin: 5px;
  }
  img {
    display: block; // Change the display from inline to block
    height: 100%; // Take the bigger property to
    aspect-ratio: 1 / 1; // Aspect-ratio is a proportional relationship
    object-fit: cover; // This property define how do yo want to fit the div
    overflow: visible;
  }
}
