@import '~styles/base.module.scss';

.modalContainer {
  position: fixed;
  right: $space-xl;
  bottom: $space-xl;

  max-width: 427px !important;
  width: 427px !important;
  flex-basis: 35em;
  margin: auto;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  max-height: 100%;
  overflow: auto;

  scrollbar-width: none;

  @include media('<small') {
    min-width: 100% !important;
    height: 100%;
    right: auto;
    bottom: auto;
    max-width: 100%;
    max-height: 100% !important;
    border-radius: 0;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: none !important;
  }
}
.content {
  height: 100% !important;
  padding-top: $space-m !important;
  padding-bottom: $space-l !important;
  width: 85%;
  margin: 0 auto;
}
.modal {
  margin: 75px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
    padding: 30px !important;
  }
}
.header {
  margin: 6% 3% 0% 5%;
  font-weight: 500 !important;
  font-size: 22px !important;
  @include media('<small') {
    margin: 3% 5% 4% 5%;
  }
}

.subtitle {
  margin: 6% 3% 0% 5%;
  font-weight: 500 !important;
  font-size: 16px !important;
  @include media('<small') {
    margin: 3% 5% 4% 5%;
  }
}

.title {
  padding: 0px !important;
  margin: 24px 184px 0px 70px !important;
  font-weight: bold;

  h2 {
    font-size: 21.33px !important;
    font-weight: 500 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  position: relative;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;
  label {
    margin-left: 5px !important;
  }
}

.infoPublicWrapper {
  display: flex;
  font-size: 12px !important;
  padding: 1% 1% 0 3% !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.sensorIdInfoLabel {
  font-size: 12px !important;
  color: $color-light-gray;
}

.error {
  color: $color-red;
  margin: 0 !important;
}

.autocompleteInput {
  border: 2px solid gray !important;
  border-radius: 32px !important;
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  position: relative;
  width: 100%;
  align-items: center;
  color: $color-primary !important;
  label {
    margin-left: 5px !important;
  }
}

.descriptionInput {
  margin-top: $space-m !important;
  min-height: 205px !important;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;
  label {
    margin-left: 5px !important;
  }
}

.button {
  margin-top: $space-l !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
  }
}
