@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 500px !important;

  min-height: 70%;
  max-height: 90vh !important;

  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  padding-bottom: $space-m !important;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    max-height: 100vh !important;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  /*
  @include media("<small") {
    justify-content: center;
    padding-top: 5px;

  }
  @include media("<370px") {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  */
  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  //margin-bottom: $space-m;
  //margin-left: $space-xl;
  //margin-top: $space-m;
  margin: 7% 5% 3% 13%;
  //width: 50%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  /* height: 5%; */
  //margin: 9px;
  align-content: center;
  padding-inline: 10px;
  height: 7%;
  padding-bottom: 10%;
  padding-top: 10px;
}

.subheaderParamsSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.subheaderParams {
  padding: 4px;
  font-size: 12px;
}

.subheaderDateandFormatSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.subheaderDateandFormat {
  padding: 4px;
  font-size: 12px;
}

.subInfo {
  color: $color-dark-gray;
  margin-bottom: $space-s;
}

.selectAllCheckboxForm {
  display: flex;
  align-items: flex-start;
  //width: 100%;
  margin: 0;

  //display: flex;
  span {
    font-size: 18px !important;
    //font-weight: 600;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.label {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 18px !important;
}

.labelNext {
  margin-top: 15px !important;
}

.checkboxWrapper {
  padding: 7%;
  // height: 200px;
  display: flex;
  flex-flow: column wrap;
}

.hasErrorCheckBox {
  border-color: $color-red !important;
}

.error {
  color: $color-red;
  margin-bottom: 10px;
}

.infoPublicWrapper {
  display: flex;
  padding-bottom: 10%;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.iconPublic {
  padding-right: 5%;
}

.downloadStatisticsInfoLabel {
  font-size: 12px !important;
}

.noDataMessage {
  color: #d93b23;
}

.hasError {
  margin-bottom: 10px !important;

  div:first-child {
    &:before {
      border-bottom: none !important;
    }

    border-bottom: 2px solid $color-red !important;
  }
}

.alert {
  margin-bottom: $space-m;
}

.checkboxFormDiv {
  padding-top: 0;
  display: inline-block !important;
  align-items: flex-start;
  padding: 2px;
  padding-right: 10px;
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  //width: 100%;
  margin: 0;

  //display: flex;
  span {
    font-size: 14px !important;
    //font-weight: 600;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    //border: 1px solid black  !important;
    //border-radius: 3px;
    //background-color: #009BE5;
    margin: 0;
    padding: 0;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  // background-color: #fafafa;
  background-color: $color-white !important;
  //border: 1px solid $color-border;
  //border-radius: 1px;
  text-align: center;

  //border: 1px solid black  !important;
  //border-radius: 3px;
  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }
}

.checkboxIcon {
  background-color: $color-primary;
  color: $color-white !important;
  border-radius: 4px;
}

.buttonWrapper1 {
  text-align: center;
  margin: 0;
  margin-top: 20% !important;
}

.buttonWrapper {
  text-align: center;
  margin: 0;
}

.buttonSiguiente {
  margin-top: 25px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonAdd {
  margin-top: 30px;
  border-radius: 30px;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark;
  }

  &:focus {
    border-color: transparent !important;
    outline: none !important;
    border: none !important;
  }
}

.buttonCancelar {
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:disabled {
    color: $color-extra-light-gray;
    background-color: $color-white;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.arrow {
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.getMoreRange {
  font-size: 11px !important;
  color: $color-primary !important;
}

.required {
  &:after {
    display: inline;
    color: $color-red;
    content: '*';
    margin-left: 2px;
  }
}

.picker {
  display: flex;
  flex-flow: column;
}

.labelPicker {
  margin-bottom: 10px !important;
}

.datePicker {
  margin-bottom: 15px;

  button:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  div:hover:before {
    border-bottom: 2px solid rgba(0, 155, 229, 0.87) !important;
  }

  div:after {
    border-bottom: none !important;
  }

  div:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.datePicker.hasError {
  &:before {
    border-bottom: none !important;
  }

  div:hover:before {
    border-bottom: none !important;
  }

  div:after {
    border-bottom: none !important;
  }
}

.datePickerWrapper {
  padding: 7%;
  // height: 200px;
  display: flex;
  flex-flow: column wrap;
}

.radioGroup {
  display: flex;
  padding-left: $space-m;

  label {
    margin-right: 50px;
  }
}

.intervalGroup {
  margin-bottom: $space-m;
}

.radioItem {
  svg {
    color: #009be5;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: $color-primary-hover !important;
  }
}

.labelRadio {
  margin-bottom: 0 !important;

  span {
    font-size: 14px !important;
  }
}
