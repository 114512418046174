@import '~styles/base.module.scss';

.centerModal {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modalContent {
  height: 100% !important;
  width: 100%;
  margin: auto auto;
}

.content {
  height: 100% !important;
  padding: $space-l !important;
  width: 70%;
  margin: 0 auto;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.modalContainer {
  max-width: 1024px !important;
  width: 800px !important;

  margin: auto;
  box-shadow: 2px;
  z-index: 2000;
  padding: $space-l !important;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: visible !important;

  @include media('<small') {
    position: center;
    padding: $space-l !important;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.closeModalContainer {
  max-width: 323px !important;
  width: 323px !important;
  flex-basis: 35em;
  margin: auto;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  @include media('<small') {
    position: center;
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    backdrop-filter: none !important;
  }
}

.closeModalContainerContent {
  height: 100% !important;
  padding-top: $space-m !important;
  padding-bottom: $space-l !important;
  width: 85%;
  margin: 0 auto;
}

.closeModalContainerContentText {
  padding: $space-m;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.closeModalContainerContentDetail {
  padding: $space-m $space-s $space-s 0;
  text-align: center;
}

.closeModalContainerButtonCancel {
  float: left;
  padding: $space-s $space-m;

  border-radius: 38px;
  border-color: transparent;

  background-color: $color-white;
  color: $color-primary;

  margin-bottom: $space-m;
  margin-right: 8px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.closeModalContainerButtonConfirm {
  float: right;
  border-radius: 38px;
  border-color: transparent;

  background-color: $color-error;
  color: $color-white;

  padding: $space-s $space-m;
  margin-bottom: $space-m;
  margin-right: 8px;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 450px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.mainPageWrapper {
  position: relative;
  text-align: center;
}

.pageWrapper {
  box-shadow: none !important;
}

.header {
  margin: 3% 5% 3% 5%;
  font-weight: 500 !important;
  font-size: 26px !important;
}

.logoImage {
  width: 300px !important;
  height: 100px !important;
}

.backdrop {
  backdrop-filter: blur(3px);
}

.closeBackdrop {
  backdrop-filter: blur(50px) !important;

  @include media('<small') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.closeModalContainerHeader {
  margin: 6% 3% 0% 5%;
  font-weight: 500 !important;
  font-size: 22px !important;

  @include media('<small') {
    margin: 3% 5% 4% 5%;
  }
}

.closeModalContainerButtonWrapper {
  text-align: center;
}

.titleWrapper {
  text-align: center;
}

.carousel {
  min-height: 450px;
}

.carouselTitleWrapper {
  text-align: center;
  display: flex;
}

.title {
  padding: $space-m !important;

  color: #424242;

  h2 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.carouselTitle {
  width: 80%;
  padding: $space-m !important;
  color: $color-primary;

  h2 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.mainDescription {
  font-size: 20px !important;
  color: $color-light-gray;
}

.imageWrapper {
  text-align: center;
  margin-top: $space-xl;
}

.descriptionWrapper {
  padding: $space-s $space-xl;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  @include media('<small') {
    padding: $space-s $space-l;
    border-radius: 21px;
    width: 100%;
  }
}

.imageTextWrapper {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: $space-m;
  margin-bottom: $space-m;
  font-size: 22px !important;
  text-align: center;
}

.carouselArrowButtons {
  background-color: $color-white !important;
  color: $color-dark-gray !important;
  border-radius: 0 !important;
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-primary-light !important;
  align-items: center;
  z-index: 9999;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.closeModalContainerTitleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.iconClose {
  width: 40px !important;
  height: 40px !important;
}

.button {
  width: 100%;
  margin-top: $space-l;
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.exploreButton {
  margin-top: $space-l;
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonWrapper {
  width: 100% !important;
  text-align: center;
}

.understoodButton {
  margin-left: auto;
  margin-right: auto;
  margin-top: $space-l;
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.refuseButton {
  color: white !important;
  margin-top: $space-l !important;
  text-transform: capitalize;
  padding: $space-m !important;
  border-radius: 53px !important;
  background-color: $color-error !important;
  width: 200px !important;
  padding: $space-s !important;

  &:hover {
    box-shadow: none;
    background-color: $color-extra-extra-light-gray;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    margin-top: $space-l;
    float: inherit;
  }
}

.acceptButton {
  color: white !important;
  margin-top: $space-l !important;
  text-transform: capitalize;
  width: 200px !important;
  padding: $space-s !important;
  border-radius: 53px !important;
  background-color: $color-primary !important;

  &:hover {
    box-shadow: none;
    background-color: $color-extra-extra-light-gray;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    margin-top: $space-l;
    float: inherit;
  }
}

.lastPageExitButton {
  color: $color-text;
  margin-top: $space-m;

  border-radius: 53px;

  &:hover {
    box-shadow: none;
    background-color: $color-extra-extra-light-gray;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    margin-top: $space-l;
    float: inherit;
  }
}

.lastMessageWrapper {
  font-size: 36px !important;
  color: $color-light-gray;
  text-align: center;
  padding: $space-xl;
}

.spaces {
  display: flex;
  justify-content: space-around;
}
