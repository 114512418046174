@import '~styles/base.module.scss';

:global {
  html {
    height: 100%;
  }

  #root {
  }

  body {
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility !important;
    background-color: $color-body-bg;
    color: $color-text;
    font-size: 14px;
    line-height: 1.5;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    position: relative;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: $color-primary;
    text-decoration: none;
    cursor: pointer;
  }
}

.component {
}
