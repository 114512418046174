@import '~styles/base.module.scss';

.modalContainer {
  max-width: 472px !important;
  width: 472px !important;
  flex-basis: 35em;

  max-height: 90vh !important;

  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 22px !important;
    border-radius: 0 !important;
    max-height: 92vh !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
  }
}

.header {
  padding: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;

  @include media('<small') {
    padding: 3% 4% 3% 4%;
  }
}

.modal {
  margin: 35px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.wrapperInfo {
  padding-top: 10px;
  display: flex;
  max-height: 55px !important;
  align-items: center;
  margin: 0 !important;

  span {
    font-size: 14px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.wrapperInfoButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;
  padding-left: 10px;
  text-transform: unset !important;
  background-color: $color-white;

  &:hover {
    cursor: pointer !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    font-size: 0;
  }
}

.infoButton {
  padding: 0 !important;
  position: relative;
  color: rgba(66, 66, 66, 0.8);

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  &:hover {
    background-color: $color-white !important;
    color: rgba(66, 66, 66, 1);
  }
}

.wrapperDataInfo {
  display: flex !important;
  align-content: baseline !important;
  align-items: center;
  margin-left: auto;
  justify-content: space-between;
}

.cajaEmail {
  background-color: $color-white;
  max-width: 400px;
  border-radius: 30px;
  z-index: 7000 !important;
  position: fixed;
  top: 40%;
  left: 49%;
  transform: translate(-54%, -50%);
  margin: 2%;
  padding: 30px;
  padding-bottom: 13px;
  box-shadow: 0 0 5px grey !important;

  @include media('<small') {
    margin: 0%;
    min-width: 300px;
    padding: 5%;
    top: 50%;
    left: 50%;
    transform: translate(-54%, -50%);
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  z-index: 6000;
  /* Below the modal, but above other content */
}

.wrapperError {
  height: 7px !important;
}

.error {
  margin: 0 !important;
  padding: 0 !important;
  color: $color-red;
}

.input {
  margin-bottom: 0.25em !important;
  position: relative;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;

  input {
    padding: 18.5px 14px !important;
  }

  label {
    margin-left: 10px !important;
  }
}

.inputSearch {
  margin-bottom: 0.25em !important;
  position: relative;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;

  input {
    padding: 14px 14px !important;
  }

  label {
    margin-left: 10px !important;
  }
}

.cselect {
  margin-top: 1.05em !important;
  margin-bottom: 0 !important;
}

.custom {
  margin-bottom: 0 !important;
  width: 90% !important;
}

.infoIcon {
  margin-right: $space-s !important;
  width: 16px !important;
  height: 16px !important;
}

.helper {
  padding-top: $space-s;
  font-size: 14px;
  line-height: 1.25em;
}

.viewer {
  font-size: 14px;
  line-height: 1.25em;
}

.subInfo {
  color: rgba(66, 66, 66, 0.8) !important;
  padding-top: $space-m;
  font-size: 18px;
}

.selectAllCheckbox {
  display: flex;
  align-items: flex-start;
  margin-left: 0 !important;
  margin-top: $space-m;

  span {
    font-size: 18px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }

  &:disabled {
    svg {
      color: black;
    }
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.devicesListPaper {
  height: 200px !important;
  max-height: 200px !important;
  overflow: auto;
  position: static !important;
  margin-bottom: $space-m !important;
}

.devicesList {
  max-height: 100%;
  overflow-x: hidden;
}

.noGroupWrapper {
  margin-bottom: $space-l;
}

.groupListItem {
  cursor: auto;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.expandIcon {
  cursor: pointer !important;
}

.listItemText {
  flex: 0 0 auto !important;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.typeListItem {
  cursor: auto;
  padding-bottom: $space-s !important;
  padding-top: $space-s !important;
  margin-left: $space-m !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.micaTypeItemText {
  flex: 0 0 auto !important;
  margin-left: $space-s;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.micaItem {
  flex: 0 0 auto !important;
  margin-left: $space-xl;
}

.navItem {
  margin-left: $space-xs;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  color: $color-primary;

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 16px !important;
    margin-right: 7px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  font-weight: 600px !important;
  position: static;
}

.roleButtonWrapper {
  margin-top: 17px;
}

.buttonSiguiente1 {
  margin-top: 13%;
  border-radius: 78px !important;
  background-color: $color-primary !important;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;
  border: transparent !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgb(198, 230, 248);
  }

  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.helperBottom {
  padding-top: $space-s;
  padding-bottom: 7%;
  font-size: 14px;
  line-height: 1.25em;
}

.errorMessage {
  color: $color-error !important;
  margin: $space-l;
  background-color: rgba($color-error, 0.2) !important;
}
.moreInfoButtonWrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}
.moreInfoButton {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  color: $color-white;
  border-radius: 64px;
  border-color: transparent;
  padding: 10px 20px;
  font-weight: 500;

  &:link {
    text-decoration: none;
  }

  &:hover {
    background-color: $color-primary-dark;
    color: white;
  }
}
.downButtonsWrapper {
  margin-bottom: 17px;
}
