@import '~styles/base.module.scss';

.circlesWrapper {
  @media all and (max-height: 570px) {
    height: calc(110vh - 105px - 68px - 15px - 135px - 30px - 19px - 0.75rem - 15px) !important;
  }
}

.header {
  width: 100%;
  min-height: 10% !important;
}

.paramList {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  padding: 0px 20px;
  height: 100%;

  .nopadding {
    padding: 0px;
  }

  @include media('<800px') {
    min-height: auto;
  }
}

.full_width {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include media('>991px') {
    height: calc(99% - 1.2rem);
  }
}

.fwidth {
  width: 100%;
}

.boxIndicators {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include media('<992px') {
    width: 20%;
  }

  @include media('<700px') {
    width: 30%;
  }

  @include media('<500px') {
    width: 40%;
  }
}

.title {
  width: 100%;
  text-align: center;
  padding-bottom: $space-l !important;

  @media screen and (max-height: '650px') {
    padding-bottom: $space-xs !important;
    font-size: 20px;
  }

  @media screen and (max-height: '800px') {
    padding-bottom: $space-m !important;
    font-size: 23px;
  }
}

.indicatorText {
  width: 100%;
  padding-top: $space-l !important;
  text-align: center;
  font-size: 30px;
  font-weight: 300;

  @media only screen and (max-width: 1800px) and (min-width: 1650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 1650px) and (min-width: 1400px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 1400px) and (min-width: 959px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 959px) and (min-width: 500px) {
    padding-top: $space-m !important;
    font-size: 14px;
  }

  @media only screen and (max-width: 500px) {
    padding-top: $space-m !important;
    font-size: 16px;
  }

  @media screen and (max-height: '800px') {
    padding-top: $space-m !important;
  }

  @media screen and (max-height: '650px') {
    padding-top: $space-s !important;
  }
}

.noParams {
  width: 30%;
  height: 100%;
}

.paramtersBox {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  row-gap: 7vh;
  padding: $space-s 0;
  margin-top: auto;
  margin-bottom: auto;

  @include media('<tablet') {
    row-gap: 30px;
    padding: $space-s 0;
  }

  @include media('<992px') {
    row-gap: 30px;
    padding: $space-m 0;
  }

  @media screen and (max-height: '650px') {
    row-gap: $space-m;
  }
}

.fullHeight {
  width: 100%;
  height: 95%;
  display: flex;
  align-items: center;
  padding: auto;
}

.foot {
  display: flex;
  width: 100%;
  padding: 0px 20px;
}

.footResponsive {
  display: flex;
  width: 100%;
  padding: 0px 20px;

  @include media('>991px') {
    display: none;
  }
}

.responsiveFooter {
  @include media('>991px') {
    display: none;
  }

  @include media('<tablet') {
    display: none;
  }

  display: flex;

  width: 100%;
  padding: 0px 20px;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .headerRow {
  @include media('>991px') {
    flex: 0 1 auto;
    min-height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .content {
  @include media('>991px') {
    flex: 1 1 auto;
  }
}

.box .footer {
  flex: 0 1 auto;

  @include media('<992px') {
    display: none;
  }
}

.indicatorDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-height: '750px') {
    width: 70% !important;
  }

  @include media('<tablet') {
    width: 100% !important;
  }
}

.titleIndic {
  width: 100%;
  font-size: 35px;
  text-align: center;
  padding-bottom: $space-l !important;

  @media only screen and (max-width: 1800px) and (min-width: 1650px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 1650px) and (min-width: 1400px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 1400px) and (min-width: 959px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 959px) and (min-width: 500px) {
    padding-bottom: $space-m !important;
    font-size: 15px;
  }

  @media only screen and (max-width: 500px) {
    padding-bottom: $space-m !important;
    font-size: 18px;
  }

  @media screen and (max-height: '800px') {
    padding-bottom: $space-m !important;
  }

  @media screen and (max-height: '650px') {
    padding-bottom: $space-s !important;
  }
}

.inbiotLink {
  display: flex;
  width: 100%;
  padding: 0px 20px;

  @include media('>=tablet') {
    display: none;
  }
}

.logo {
  height: 30px !important;
}

.linkStyle {
  color: black;
  padding-left: $space-s;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:visited {
    color: black;
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.alignLink {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  padding: 5px 15px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
}
