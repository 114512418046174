@import '~styles/base.module.scss';

.component {
  bottom: 0;
  position: absolute;
  width: 100%;
  color: $color-black;
  background-color: $color-white;
  margin-top: auto;
  padding: $space-xs;

  @include media('<tablet') {
    position: relative;
  }
}

.content {
  position: relative;
  padding: $space-xs $space-m;
  margin: 0 auto;
  max-width: $view-max-width;
  padding-bottom: $space-m;

  @include media('<tablet') {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.footerContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media('<tablet') {
    display: block;
  }
}

.footerSeparator {
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.6);

  @include media('<tablet') {
    width: 100%;
  }
}

.languageDropdown {
  width: 100%;
  display: flex;
}

.languageMenu {
  padding: 0 !important;
}

.language {
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  align-items: center;

  @include media('<tablet') {
    display: none;
  }
}

.languageTitle {
  padding-left: 10%;
  font-size: 12px;
}

.measurementSystemTitle {
  font-size: 12px;
  margin-left: 2px;
}

.menuItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.5;
  font-family: 'Roboto';

  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  background-color: none;
  // padding: $space-s $space-m;
  &:hover {
    background-color: rgba(0, 155, 229, 0.4) !important;
  }
}

.menuLanguageContent {
  padding: 0 !important;
}

.menuLanguagePaper {
  border: 1px solid #dddddd;
  border-radius: 15px;
  margin-top: -$space-l;
  padding-bottom: 0;
  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 130px;
}

.menuMeasurementsSystemPaper {
  border: 1px solid #dddddd;
  border-radius: 15px;
  margin-top: -$space-l;
  padding-bottom: 0;
  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 230px;
}

.buttonLanguages {
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 14px;
  padding: 0;
  border-radius: 0;
  z-index: 0;
  color: rgba(66, 66, 66, 0.8);
  text-transform: none;

  &:hover {
    background: transparent;
    border-radius: 0;
    padding-bottom: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
  }
}

.languageSelectedIcon {
  width: 40px;
}

.navItem {
  margin-right: $space-m;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color ease 0.3s;
  color: inherit;

  @include media('<tablet') {
    margin-right: $space-s;
    min-width: 48px;
  }
}

.contact {
  display: flex;
  float: left;
  font-size: 12px;
  a {
    margin-left: $space-l;
  }

  @include media('<tablet') {
    padding-top: 5px;
    justify-content: space-between;
    width: 100%;
    font-size: 10px;
    margin-bottom: $space-l;
    a {
      margin-left: 0;
      margin-right: $space-m;
    }
  }

  @include media('<320px') {
    margin-bottom: $space-l;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      margin-top: $space-s !important;
    }
  }
}

.copyrightText {
  font-size: 10px;
}

.link {
  color: $color-black !important;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
