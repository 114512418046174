@import '~styles/base.module.scss';

.component {
  min-height: 100vh;
}

.content {
  position: relative;
  width: 100%;
  min-height: 100vh;

  @include media('<tablet') {
    padding-bottom: 0;
  }
}
