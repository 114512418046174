@import '~styles/base.module.scss';

.centerModal {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  max-width: 1024px !important;
  width: 1024px !important;

  margin: auto;
  box-shadow: 2px;
  z-index: 2000;
  padding: $space-l !important;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: visible !important;

  @include media('<small') {
    position: center;
    padding: 0 $space-m !important;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.closeModalContainer {
  max-width: 323px !important;
  width: 323px !important;
  flex-basis: 35em;
  margin: auto;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  @include media('<small') {
    position: center;
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    backdrop-filter: none !important;
  }
}

.closeModalContainerContent {
  height: 100% !important;
  padding-top: $space-m !important;
  padding-bottom: $space-l !important;
  width: 85%;
  margin: 0 auto;
}

.closeModalContainerContentText {
  padding: $space-m;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.closeModalContainerContentDetail {
  padding: $space-m $space-s $space-s 0;
  text-align: center;
}

.closeModalContainerButtonCancel {
  float: left;
  padding: $space-s $space-m;

  border-radius: 38px;
  border-color: transparent;

  background-color: $color-white;
  color: $color-primary;

  margin-bottom: $space-m;
  margin-right: 8px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.closeModalContainerButtonConfirm {
  float: right;
  border-radius: 38px;
  border-color: transparent;

  background-color: $color-error;
  color: $color-white;

  padding: $space-s $space-m;
  margin-bottom: $space-m;
  margin-right: 8px;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.mainPageWrapper {
  position: relative;
  text-align: center;
}

.contentPageWrapper {
  position: relative;
  text-align: center;

  @include media('<small') {
    overflow-y: auto;
    height: 90%;
  }
}

.pageWrapper {
  box-shadow: none !important;
}

.header {
  margin: 3% 5% 3% 5%;
  font-weight: 500 !important;
  font-size: 26px !important;
}

.contentWrapper {
  width: 100%;
  justify-content: center;
  display: flex;

  @include media('<small') {
    display: block;
  }
}

.contentWrapper {
  width: 100%;
  padding: 2%;
  justify-content: center;
  display: flex;
}

.mobileContentWrapper {
  display: none;

  @include media('<small') {
    display: block;
  }
}

.mainPageImageWrapper {
  width: 60%;

  @include media('<small') {
    width: 100%;
    border-radius: 0;
  }
}

.contentPageImageWrapper {
  width: 70%;

  @include media('<small') {
    width: 100%;
    border-radius: 0;
  }
}

.mainPageDescription {
  width: 100%;
  padding: 3%;
  text-align: center;

  @include media('<small') {
    width: 100%;
    padding: 0;
  }
}

.mainPageDescriptionText {
  font-weight: 400;
  font-size: 28px;
  padding: 0 2%;

  color: $color-dark-gray;

  @include media('<small') {
    font-size: 20px;
  }
}

.bannerDescriptionText {
  font-size: 18px;
  font-weight: 300;
}

.mainDescription {
  font-size: 20px !important;
  color: $color-light-gray;
}

.logoImage {
  width: 90px !important;
  height: 90px !important;
}

.heatLogoImage {
  width: 45px !important;
  height: 45px !important;

  @include media('<small') {
    width: 30px !important;
    height: 30px !important;
  }
}

.backdrop {
  backdrop-filter: blur(3px);
}

.closeBackdrop {
  backdrop-filter: blur(50px) !important;

  @include media('<small') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.closeModalContainerHeader {
  margin: 6% 3% 0% 5%;
  font-weight: 500 !important;
  font-size: 22px !important;

  @include media('<small') {
    margin: 3% 5% 4% 5%;
  }
}

.closeModalContainerButtonWrapper {
  text-align: center;
}

.iconWrapper {
  text-align: center;
  padding-top: $space-xl;

  @include media('<small') {
    padding: 0;
    display: block;
    visibility: hidden;
  }
}

.contentDescriptionTitle {
  text-align: center;
  padding-top: $space-l;
  padding-left: 12%;
  padding-right: 12%;
}

.contentPageDescriptionTitleText {
  font-weight: 400;
  font-size: 24px;

  color: $color-primary;

  @include media('<small') {
    font-size: 18px;
  }
}

.contentDescription {
  text-align: center;
  margin-bottom: $space-l;
  padding-left: 3%;
  padding-right: 3%;
}

.contentPageDescriptionText {
  font-weight: 400;
  font-size: 20px;

  color: $color-dark-gray;

  @include media('<small') {
    font-size: 14px;
  }
}

.carousel {
  min-height: 450px;
  margin-top: $space-m;
}

.carouselTitleWrapper {
  text-align: center;
  display: flex;
}

.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  padding: $space-m !important;
  color: $color-primary;

  h2 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  @include media('<small') {
    padding: $space-s !important;

    font-size: 26px !important;
  }
}

.carouselTitle {
  width: 100%;
  padding: $space-m !important;
  color: $color-primary;

  h2 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  @include media('<small') {
    margin-top: $space-l;
    font-size: 24px !important;
  }
}

.imageWrapper {
  min-height: 400px;
  max-height: 500px;
  text-align: center;
  margin-top: $space-s;

  @include media('<small') {
    display: none;
  }
}

.imageMobileWrapper {
  display: none;

  @include media('<small') {
    display: block;
    text-align: center;
  }
}

.imageMobile {
  max-height: 400px;
}

.descriptionWrapper {
  padding: $space-s $space-xl;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  @include media('<small') {
    padding: $space-s $space-l;
    border-radius: 21px;
    width: 100%;
  }
}

.imageTextWrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: $space-m;
  margin-bottom: $space-s;
  font-size: 22px !important;
  text-align: center;
}

.carouselImage {
  @include media('<small') {
    max-height: 400px;
  }

  @media all and (max-height: 650px) {
    height: 450px !important;
  }

  @media all and (max-height: 580px) {
    height: 400px !important;
  }

  @media all and (max-height: 500px) {
    height: 350px !important;
  }

  @media all and (max-height: 450px) {
    height: 300px !important;
  }
}

.carouselArrowButtons {
  background-color: $color-white !important;
  color: $color-dark-gray !important;
  border-radius: 0 !important;
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 0;
  color: $color-primary-light !important;
  align-items: center;
  z-index: 9999;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.closeModalContainerTitleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.iconClose {
  width: 40px !important;
  height: 40px !important;
}

.button {
  width: 100%;
  margin-top: $space-l;
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.knowMoreButton {
  display: inline-block;
  padding: $space-s $space-xl;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary !important;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    margin-top: $space-m;
  }
}

.exploreButton {
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary !important;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    margin-top: $space-m;
  }
}

.buttonWrapper {
  width: 100% !important;
  text-align: center;
}

.understoodButton {
  margin-left: auto;
  margin-right: auto;
  margin-top: $space-s;
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.exitButton {
  color: $color-light-gray;
  margin-top: $space-s;
  text-transform: capitalize;

  border-radius: 53px;

  &:hover {
    box-shadow: none;
    background-color: $color-extra-extra-light-gray;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    margin-top: $space-m;
    float: inherit;
  }
}

.lastPageExitButton {
  color: $color-text;
  margin-top: $space-m;

  border-radius: 53px;

  &:hover {
    box-shadow: none;
    background-color: $color-extra-extra-light-gray;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    margin-top: $space-l;
    float: inherit;
  }
}

.lastMessageWrapper {
  font-size: 36px !important;
  color: $color-light-gray;
  text-align: center;
  padding: $space-xl;
}
