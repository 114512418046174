@import '~styles/base.module.scss';

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 80vw;
  width: 425px;
  max-height: 90vh !important;
  min-height: 300px;
  margin: auto;
  padding: 30px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  @include media('<small') {
    position: center;
    min-width: 100%;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding-bottom: 50px;
    overflow: scroll;
    min-height: 100%;
  }
}
.header {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.4em;
}

.textContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
}

.textFooter {
  margin: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.footer {
  width: 100%;
}

.buttonCancelar {
  background-color: $color-primary;
  color: $color-white;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: $color-primary-dark;
  }

  &:disabled {
    color: $color-extra-light-gray;
    background-color: $color-white;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}
