.loadingIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;

  img {
    width: 75px;
    display: block;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    animation: rotation 3s infinite ease-in-out;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.littleLoadingIconContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 38px;

  img {
    width: 25px;
    display: block;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    animation: rotation 3s infinite ease-in-out;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
