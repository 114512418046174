@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 500px !important;

  max-height: 90vh !important;
  height: 335px;
  margin: auto;
  padding: 30px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  padding-bottom: $space-m !important;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    max-height: 100vh !important;
  }
}
.header {
  display: flex;
  font-weight: bold;
  font-size: 1.4em;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.modalContent {
  margin: 20px 0;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0px;

  button {
    outline: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.downloadButton {
  width: 170px;
  border-radius: 30px;
  margin: 10px;
  height: 35px;
  background-color: $color-primary;
  color: #ffff;
  border: none;
  white-space: nowrap;

  &:hover {
    background-color: $color-primary-dark;
  }
}
.closeButton {
  width: 100px;
  border-radius: 30px;
  height: 35px;
  background-color: #ffff;
  color: $color-primary;
  border: none;
  margin-bottom: 0px;

  &:hover {
    border: 1px solid $color-primary;
  }
}

.fileNameInput {
  width: 100%;
  margin: 10px 2px;
  height: 35px;
  border: 2px solid #b3b3b3;
  border-radius: 25px;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  &:focus {
    outline: none;
    border-color: #009be5;
  }
  input {
    border: none;
    outline: none;
    width: 100%;
    margin: 0;
    padding-left: 7px;
    margin-right: 4px;
    height: 30px;
    background-color: transparent;
  }
  &:hover {
    svg {
      color: #009be5;
    }
  }
  svg {
    color: #686868;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    margin-left: 6px;
    margin-top: 2px;
    height: 21px;
    width: 21px;
    @include media('<tablet') {
      margin-bottom: 5px;
    }
  }
}
