@import '~styles/base.module.scss';

.progress_chart {
  max-width: 500px;
  position: relative !important;
  width: 100%;

  svg {
    width: 100% !important;
  }
}

.progress_chart__circle {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(-84deg);
  transform-origin: center;
  width: 100%;
  height: 100%;

  @include media('<tablet') {
    top: 0px;
    left: 0px;
  }
}

.progress_chart__circleLittle {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(-84deg);
  transform-origin: center;
  width: 100%;
  height: 100%;
}

.progress_chart__arc3 {
  fill: transparent;
  stroke-width: 3.9%;
  width: 100%;

  @include media('<tablet') {
    stroke-width: 3.9%;
  }
}

.spaces {
  padding: 2px;

  @include media('<tablet') {
    padding: 1px;
  }
}

.progress_chart__percentage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    width: 100%;
    text-align: center;
    font-size: 800% !important;

    @media screen and (max-height: '900px') {
      font-size: 500% !important;
    }

    @media screen and (max-height: '800px') {
      font-size: 450% !important;
    }

    @media screen and (max-height: '700px') {
      font-size: 400% !important;
    }

    @include media('<1500px') {
      font-size: 600% !important;

      @media screen and (max-height: '900px') {
        font-size: 500% !important;
      }

      @media screen and (max-height: '800px') {
        font-size: 450% !important;
      }

      @media screen and (max-height: '700px') {
        font-size: 400% !important;
      }
    }

    @include media('<1200px') {
      font-size: 400% !important;
    }

    @include media('<1100px') {
      font-size: 300% !important;
    }
  }
}

.progress_chart__percentageLittle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400 !important;
}

.indicatorsTitle {
  opacity: 0.8;
  height: 70px !important;
  width: 70px !important;

  @include media('<1150px') {
    height: 50px !important;
    width: 50px !important;
  }

  @media screen and (max-height: '700px') {
    height: 50px !important;
    width: 50px !important;
  }

  @include media('<950px') {
    height: 30px !important;
    width: 30px !important;
  }
}

.indicatorBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}
