@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 500px !important;

  max-height: 90vh !important;
  height: 300px;
  margin: auto;
  padding: 30px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  padding-bottom: $space-m !important;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    max-height: 100vh !important;
  }
}
.header {
  display: flex;
  font-weight: bold;
  font-size: 1.4em;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.modalContent {
  margin: 20px 0;
}
.modalTextLine {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
}
.textAttribute {
  font-weight: bold;
  margin-right: 3px;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;

  button {
    margin: 10px;
    outline: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.downloadButton {
  width: 170px;
  border-radius: 30px;
  height: 35px;
  background-color: $color-primary;
  color: #ffff;
  border: none;
  white-space: nowrap;
  &:hover {
    background-color: $color-primary-dark;
  }
}
.closeButton {
  width: 100px;
  border-radius: 30px;
  height: 35px;
  background-color: #ffff;
  color: $color-primary;
  border: none;

  &:hover {
    border: 1px solid $color-primary;
  }
}
