@import '~styles/base.module.scss';

.component {
  position: fixed;
  z-index: 9999999;
  bottom: $space-m;
  right: $space-m;
  pointer-events: none;
}

.loader {
  color: $color-primary;
  background: none;
  padding: 0.5em;
  user-select: none;
}

.circle {
  color: $color-primary-dark !important;
}

.fade {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 300ms ease, transform 300ms cubic-bezier(0.59, -0.57, 0.46, 1.41);

  &--entering {
    opacity: 0;
    transform: scale(0.2);
  }

  &--entered {
    opacity: 1;
    transform: scale(1);
  }

  &--exiting {
    opacity: 0;
    transform: scale(0.2);
  }
}
