@import '~styles/base.module.scss';

.component {
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  //background-image: url('~icons/background-logo.png');
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: left 0px top 75px;
}

.container {
  width: 100%;
  margin: 0 0 64px 0;
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: #ffffff;

  @include media('<tablet') {
    padding: 0 $space-m;
    max-width: none;
    padding-top: 40px;
    margin: 32px auto;
    top: 0;
  }
}

.inbiotLogo {
  text-align: center;
  margin-bottom: $space-l !important;

  @include media('<tablet') {
    display: none;
  }
}

.content {
  width: 100%;
  max-width: $auth-view-max-width;

  margin: 0 auto;
  padding-bottom: 30px;

  @include media('<tablet') {
    padding: 0 $space-m;
    max-width: none;
    padding-top: 40px;
    margin: 32px auto;
    top: 0;
  }
}
