@import '~styles/base.module.scss';

.component {
  @media (min-height: 100px) {
    overflow: hidden;
  }

  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  position: relative;
  width: 100%;
  padding-top: 70px;
  padding-bottom: $space-xl;
  margin: $space-m auto $space-l auto;
  max-width: $view-max-width;
  padding-left: $space-m;
  padding-right: $space-m;

  @include media('<tablet') {
    padding-bottom: 12px;
    padding-top: 60px;
  }
}
