@import '~styles/base.module.scss';

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.3em;
  justify-content: first baseline;

  @include media('<small') {
    margin: 1% 5% 3% 5%;
  }
}

.modal {
  margin: 60px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s $space-xs;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.subheaderDevicesSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px 6px;
  font-size: 12px;
  margin-right: 10px !important;
}

.subheaderParams {
  padding: 4px;
  font-size: 12px;
  margin-right: 10px !important;
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  font-weight: 600 !important;
  position: static;
}

.buttonSiguiente1 {
  margin-top: 15px;
  border-radius: 78px !important;
  background-color: $color-primary !important;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.nav {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 7%;

  padding: 10px 0px 3% 0px;
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgb(198, 230, 248);
  }

  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.input {
  margin-top: 0.25em !important;
  margin-bottom: 0.25em !important;
  text-align: start !important;
  width: 100%;
  padding: 0.3%;
  align-items: center;
  color: $color-primary !important;

  label {
    margin-left: 10px !important;
  }
}

.selectAllCheckbox {
  display: flex;
  align-items: flex-start;
  margin: 0;
  margin-top: $space-m;

  span {
    font-size: 18px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.devicesListPaper {
  height: 300px !important;
  max-height: 300px !important;
  position: static !important;
}

.devicesList {
  max-height: 100%;
  overflow-x: hidden;
}

.groupListItem {
  cursor: auto;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.expandIcon {
  cursor: pointer !important;
}

.listItemText {
  flex: 0 0 auto !important;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.typeListItem {
  cursor: auto;
  padding-bottom: $space-s !important;
  padding-top: $space-s !important;
  margin-left: $space-m !important;

  &:hover {
    background-color: $color-white !important;
  }
}

.micaItem {
  flex: 0 0 auto !important;
  margin-left: $space-xl;
}

.checkboxForm {
  display: flex !important;
  align-items: flex-start !important;
  margin: 0 !important;

  span {
    font-size: 16px !important;
    margin-right: 7px !important;
    line-height: 1.5 !important;
    padding: 0px !important;
    border-radius: 4px !important;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }

  &:disabled {
    svg {
      color: black;
    }
  }
}

.disabledBox {
  svg {
    color: rgba(0, 0, 0, 0.38) !important;
    margin: 0;
    padding: 0;
  }
}

.micaTypeItemText {
  flex: 0 0 auto !important;
  margin-left: $space-s;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.deviceNumber {
  width: 100%;
  color: #424242;
  font-size: 14px;
  margin-top: $space-m;
}

.infoIcon {
  margin-right: $space-s !important;
  width: 15px !important;
  height: 15px !important;
}

.hover_visible {
  position: relative;
}

.hover_visible:hover {
  .information_box {
    display: block;

    top: -170px !important;
    left: 10px !important;

    @include media('<tablet') {
      top: -130px !important;
    }
  }
}

.information_box {
  position: absolute;
  z-index: 9999999999999999;

  display: none;
  padding: 0 0 0 10px;

  .information {
    width: 240px;
    padding: 25px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    font-family: roboto;

    @include media('<tablet') {
      width: 190px;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }

  p {
    font-size: 15px;
    text-align: left;
    max-width: 270px;
  }
}

.noGroupWrapper {
  margin-bottom: $space-l;
}

.hasError {
  border-color: $color-red !important;
}

.error {
  color: $color-red !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.subInfo {
  display: table-cell;
  vertical-align: middle;
  color: #424242 !important;
  opacity: 0.4;
  padding-bottom: 12px;
  padding-top: $space-m;
  font-size: 18px !important;
}

.radioFormDiv {
  padding-top: 0;
  display: flex !important;
  align-items: center;
  padding-right: 10px;
  margin-bottom: $space-m;
}

.buttonSiguiente {
  margin-top: 215px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  box-shadow: 3px 4px 3px rgb(168, 167, 167) !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: #006db3 !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none !important;
    border: none !important;
  }

  .arrow {
    margin-left: 0.35em !important;
    margin-right: 0 !important;
  }
}

.buttonsBottom {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.form {
  height: 100%;
  position: relative;
}
