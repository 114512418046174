@import '~styles/base.module.scss';

.content {
  height: 100% !important;
  padding: $space-l !important;
  margin: auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
  @include media('<small') {
    padding: 0 !important;
  }
}
