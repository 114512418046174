@import '~styles/base.module.scss';

.content {
  margin: 0 auto;

  @include media('<small') {
    height: 100%;
    width: 100%;
  }
}
